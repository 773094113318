<template>
    <div style="text-align: left; width: fit-content; background-color: white;">
        <b-form inline id="search-step-1" style="padding-left: 1px; background-color: white; margin-top: 1px;margin-left: -10px;">
            <b-button-group id="search-step-1" style="border: 1px solid gray; padding: 3px; margin: 1px 5px 3px -2px;
                            background-color: rgb(232, 232, 232); border-radius: 3px;">

                <b-form-select size="sm" v-model="source" :options="sourceOptions" style="; border:1px solid gray; margin: 1px -10px 0 0"></b-form-select>

                <span v-if="source !== 'spread'" style="border-radius: 4px; margin: 1px 5px 1px 10px">
                    <b-form-select size="sm" v-if="source == 'singleCommodity'" v-model="commodity" :options="commodityOptions" style="border:1px solid gray;
                                   margin: 0px 0 -3px 0" ></b-form-select>
                    <group-selector v-if="source === 'group'" v-bind:namespace="namespace"></group-selector>
                </span>

                <div style="display: flex;" >
                    <b-form-group label="legs: " v-slot="{ ariaDescribedby }" class="legs">
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="legs"
                            :options="legOptions"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                            style="color: black"
                            ></b-form-checkbox-group>
                    </b-form-group>
                </div>

                <b-form-select size="sm" v-model="position" :options="positionOptions"  style="border:1px solid gray; margin:  1px 7px 0 7px"></b-form-select>

                <commodity-box v-if="source === 'spread'" v-bind:namespace="namespace" style="margin: 0 4px 0 10px"
                               v-bind:max-legs="3"
                               v-bind:showMultSelector="false">
                    <template v-slot:contract-aligner>
                        <contract-aligner-nearest-open-selector v-bind:namespace="namespace"
                                                                v-bind:title="' Spread Aligner'"></contract-aligner-nearest-open-selector>     
                    </template>
                </commodity-box>   

                <div  v-if="legs.some(x => x > 1)">
                    <!--  <b-form-select style="border:1px solid gray; border:1px solid gray; margin-top: 1px; border-top-right-radius: 0px;
                                     border-bottom-right-radius: 0px;"
                                     v-model="numberOfContractsApartComparator" :options="comparatorOptions"></b-form-select> -->
                    <span style="margin: 0px 5px;"> contracts apart</span>
                    <b-form-select size="sm" style="border:1px solid gray; width: auto; border: 1px solid gray; margin: 1px 0 0 -2px; border-radius: 4px;"
                                   v-model="numberOfContractsApart" :options="numberOfContractsApartOptions"></b-form-select> 
                     
                </div>
                <b-button v-b-toggle.collapse-1 style="background:#9A7D0A;color:white; margin: 0px 3px" class="btn" size="sm">Period Selector</b-button>
                <b-button v-b-toggle.collapse-2 style="background:teal;color:white; margin: 0px 3px"  size="sm">Sort by</b-button>
            </b-button-group>                 
            <b-button size="sm" v-on:click="startSearchTour" style="background-color: #9A0A54;  color: white; border: 1px solid gray; margin: 0 15px 0px 30px;">Page Tour</b-button>
            <div id="search-step-0"></div>
            <div style="display: flex;border: 1px solid gray; margin-left: 2px; padding: 2px; border-radius: 3px;">
            <div style="white-space: nowrap;"><b>NCA</b> = Number of contracts separation </div>
            <div style="white-space: nowrap;"><b>&nbsp;MPE</b> = maximum profitable excursion </div>
            <div style="white-space: nowrap;"><b>&nbsp;MAE</b> = maximum adverse excursion</div>
            <div style="white-space: nowrap;"><b>&nbsp;APPD</b> = average profit per day</div></div>
            <v-tour name="searchTour" :steps="searchSteps" :options="{ debug: true }"></v-tour>

        </b-form>

        <div>
        <b-collapse id="collapse-1" class="mt-2">
            <b-form inline id="search-step-2" style="padding-left: 1px; background-color: white; margin-top: 1px;margin-left: -10px;">
                    <b-button-group style="color: white; border: 1px solid gray; padding: 3px; margin: 0px 5px 2px -2px; background-color: #9A7D0A; border-radius: 3px;">

                        <span style="padding: 9px 0px 0 5px; font-weight: bold">Period Selectors:</span>
                        <b-form-select size="sm" v-model="tradePeriodExcludedSelector" :options="tradePeriodExcludedSelectorOptions"
                                    style="border:1px solid gray; margin: 0px 6px 0px 2px;" ></b-form-select>

                        <span v-show="tradePeriodExcludedSelector !== 'startingRange'">
                            <span style="margin: 10px -2px 0 17px; font-weight: bold">From&nbsp;</span> 
                            <b-form-datepicker v-model="openStart" size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                                            style="padding-top: 3px; border: 1px solid gray; margin: 1px"></b-form-datepicker>
                            <span style="margin: 10px -1px 0 3px; font-weight: bold">To&nbsp;</span> 
                            <b-form-datepicker v-model="openEnd" size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                                            style="padding-top: 3px;border: 1px solid gray; margin: 1px"></b-form-datepicker>
                        </span>

                        <span v-show="tradePeriodExcludedSelector !== 'tradeLength'">
                            <span style="padding: 10px 2px 0 25px; font-weight: bold">trade length:</span> 
                            <b-input v-model.lazy.number="minDays" style="border: 1px solid gray; width: 50px;  text-align: right;"> </b-input>
                            <span style="padding: 6px 2px 0 5px; font-weight: bold">&le;&nbsp;days&nbsp;&le;&nbsp;</span>
                            <b-input  v-model.lazy.number="maxDays" style="border: 1px solid gray; width: 50px; text-align: right;"> </b-input>
                        </span>

                        <span v-show="tradePeriodExcludedSelector !== 'endingRange'">                 
                            <span style="margin: 10px -2px 0 19px; font-weight: bold">ending between&nbsp;</span> 
                            <b-form-datepicker v-model="closeStart" size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                                            style="padding-top: 3px; border: 1px solid gray; margin: 1px"></b-form-datepicker>
                            <span style="margin: 10px -1px 0 3px; font-weight: bold">and&nbsp;</span> 
                            <b-form-datepicker v-model="closeEnd" size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                                            style="padding-top: 3px;border: 1px solid gray; margin: 1px"></b-form-datepicker>
                        </span>
                    </b-button-group>                
            </b-form>
        </b-collapse>
        </div>
        
        <div>
        <b-collapse id="collapse-2" class="mt-2">
        <b-form inline @submit.prevent style="padding-left: 1px; margin-top: 1px;margin-left: -10px;">
                    <b-button-group id="search-step-3" 
                                    style="border: 1px solid gray; padding: 3px; margin: 1px 5px 3px -2px; background-color: teal; border-radius: 3px; color: white;">
                        <span style="padding: 9px 2px 0 5px; font-weight: bold"> sort by:</span>
                        <b-form-select size="sm" style="border:1px solid gray; margin-right: 10px; color: white; background-color: red; font-weight: bold" v-model="sortParameter" :options="sortParameterOptions"></b-form-select>
                        <span style="padding: 9px 2px 0 0; font-weight: bold">direction:</span>
                        <b-form-select size="sm" style="border:1px solid gray;" v-model="direction" :options="directionOptions"></b-form-select>
                    </b-button-group>

                    <b-col id="search-step-4" md="auto" v-for="n in userConditions.length" v-bind:key="n" style="padding:0px;">  
                        <user-condition v-bind:namespace="namespace" 
                                        v-bind:index="n-1"
                                        style=" margin: 0 4px 2px -3px"></user-condition>
                    </b-col>

                    <b-button-group style="border: 1px solid gray; padding: 2px; margin: 0 5px 2px -2px; background-color: teal; border-radius: 3px;">
                        <b-button variant="primary" size="sm"
                                v-on:click="addCondition" style="margin: 1px; border: 1px solid darkgray">add condition</b-button>
                    </b-button-group>
                </b-form>
        </b-collapse>
        </div>
    </div>
</template>

<style>

    .legs legend {
        display: contents;
    }
    .legs div {
        margin-left: 3px;
    }
</style>

<script>
    import {groupsObject, commodityMonths} from '@/js/main';
    import userCondition from '@/components/user-condition';
    import commodityBox from '@/components/commodity-box';
    import groupSelector from '@/components/group-selector';
    import contractAlignerNearestOpenSelector from '@/components/contract-aligner-nearest-open-selector';
    import moment from "moment";

    export default {
        components: {
            userCondition, commodityBox, contractAlignerNearestOpenSelector, groupSelector
        },
        created() {
            let today = moment().format("YYYY-MM-DD");

            if (this.openStart === "") {
                console.log("today=", today);
                this.$store.commit(this.namespace + '/search/setOpenStart', today);
            }
            if (this.openEnd === "") {
                this.$store.commit(this.namespace + '/search/setOpenEnd', moment().add(1, 'weeks').format("YYYY-MM-DD"));
            }

            if (this.closeStart === "") {
                console.log("today=", today);
                this.$store.commit(this.namespace + '/search/setCloseStart', moment().add(1, 'months').format("YYYY-MM-DD"));
            }
            if (this.closeEnd === "") {
                this.$store.commit(this.namespace + '/search/setCloseEnd', moment().add(1, 'months').add(1, 'weeks').format("YYYY-MM-DD"));
            }
        },
        mounted() {
            console.log("search-input.vue mounted() starting. this.namespace=", this.namespace, "  this.$store.state[this.namespace].search.openStart=", this.$store.state[this.namespace].search.openStart);
            this.makeQuery();
        },
        props: ['namespace'],
        data: function () {
            let that = this;
            return {
                selectedLegs: [], // Must be an array reference!
                legOptions: [
                    {value: 1, text: '1'},
                    {value: 2, text: '2'},
                    {value: 3, text: '3'}
                ],
                positionOptions: [
                    {value: 'long', text: 'BUY'},
                    {value: 'short', text: 'SELL'},
                    {value: 'either', text: 'BUY or SELL'}
                ],
                directionOptions: [
                    {value: 'desc', text: 'down'},
                    {value: 'asc', text: 'up'}
                ],
                tradePeriodExcludedSelectorOptions: [
                    {value: 'endingRange', text: 'starting range, trade length'},
                    {value: 'startingRange', text: 'trade length, ending range'},
                    {value: 'tradeLength', text: 'starting range, ending range'}
                ],
                comparatorOptions: [
                    {value: '<=', text: '<='},
                    // {value: '>=', text: '>='},
                    {value: '=', text: '='}
                ],
                searchSteps: [
                    /* {
                     target: '#search-step-0',  // We're using document.querySelector() under the hood
                     header: {
                     title: 'Search Tour',
                     },
                     content: `Take a brief tour.`,
                     params: {
                     enableScrolling: false
                     }
                     }, */
                    {
                        target: '#search-step-1',
                        content: 'Select the instrument.',
                        params: {
                            placement: 'left',
                            enableScrolling: false
                        },
                        before: function () {
                            return new Promise((resolve) => {
                                // Time-consuming UI/async operation here.
                                console.log("that.$parent.$parent.$parent=", that.$parent.$parent.$parent);
                                console.log("that.$parent.$parent.$parent.$refs=", that.$parent.$parent.$parent.$refs);
                                console.log("that.$parent.$parent.$parent.$refs.tabsBox=", that.$parent.$parent.$parent.$refs.tabsBox);
                                console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs.savedCharts=", that.$parent.$parent.$parent.$refs.tabsBox.$refs.savedCharts);

                                that.$parent.$parent.$parent.$refs.tabsBox.$refs.savedCharts.mini = false;
                                resolve("foo");
                            });
                        }
                    },
                    {
                        target: '#search-step-2',
                        content: 'Select the date window.',
                        params: {
                            placement: 'left', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                            enableScrolling: false
                        }
                    },
                    {
                        target: '#search-step-3',
                        content: 'Select the ranking criterion.',
                        params: {
                            placement: 'left',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '#search-step-4',
                        content: 'Add/remove further conditions.',
                        params: {
                            placement: 'left',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '#search-step-5',
                        content: 'Name the tab (optional).',
                        params: {
                            placement: 'left',
                            enableScrolling: false
                        },
                        before: function () {
                            return new Promise((resolve) => {
                                // Time-consuming UI/async operation here.
                                console.log("that.$parent.$parent.$parent=", that.$parent.$parent.$parent);
                                console.log("that.$parent.$parent.$parent.$refs=", that.$parent.$parent.$parent.$refs);
                                console.log("that.$parent.$parent.$parent.$refs.tabsBox=", that.$parent.$parent.$parent.$refs.tabsBox);
                                console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs.savedCharts=", that.$parent.$parent.$parent.$refs.tabsBox.$refs.savedCharts);

                                // that.$parent.$parent.$parent.$refs.tabsBox.$refs.savedCharts.mini = true;
                                resolve("foo");
                            });
                        }
                    },
                ]
            }
        },
        computed: {
            numberOfContractsApartComparator: {
                get() {
                    return this.$store.state[this.namespace].search.numberOfContractsApartComparator;
                },
                set(numberOfContractsApartComparator) {
                    this.$store.commit(this.namespace + '/search/set', {key: "numberOfContractsApartComparator", value: numberOfContractsApartComparator});
                    this.makeQuery();
                }
            },
            numberOfContractsApartOptions() {
                console.log("numberOfContractsApartOptions() starting. this.source=", this.source);
                let numberOfMonths;
                if (["singleCommodity", "spread"].includes(this.source)) {
                    console.log("singleCommodity");
                    let commodity = this.$store.getters[this.namespace + '/c'][0];
                    numberOfMonths = commodityMonths(commodity).length;
                } else if (this.source === "all") {
                    numberOfMonths = 12;
                } else if (this.source === "group") {
                    let commodities = groupsObject[this.group].commodities;
                    console.log("commodities=", commodities);
                    let commodityMonthsArray = commodities.map(commodity => commodityMonths(commodity).length);
                    console.log("commodityMonthsArray=", commodityMonthsArray);
                    numberOfMonths = Math.max(...commodityMonthsArray);
                }
               // console.log("numberOfMonths=", numberOfMonths);

                let lowerBound = this.legs.includes(3) ? 2 : 1;
                let temp = Array(numberOfMonths + 1 -lowerBound).fill()
                        .map((x, i) => {
                            return {value: i + lowerBound, text: i + lowerBound};
                        });
                return temp;
            },
            numberOfContractsApart: {
                get() {
                    return this.$store.state[this.namespace].numberOfContractsApart;
                },
                set(numberOfContractsApart) {
                  //  console.log("numberOfContractsApart.set() starting. numberOfContractsApart=", numberOfContractsApart); // Uncommenting causes inexplicable error.
                    this.$store.commit(this.namespace + '/setNumberOfContractsApart', numberOfContractsApart);
                    this.makeQuery();
                }
            },
            sourceOptions() {
                let disabled = this.$store.state.user.role === "guest";
                let options = [
                    {value: 'singleCommodity', text: 'commodity'},
                    {value: 'group', text: 'group', disabled: disabled},
                    {value: 'all', text: 'all commodities', disabled: disabled}
                ];
                if (this.$store.state.user.email === "danscarr9@gmail.com") {
                    options.push({value: 'spread', text: 'contract or spread'});
                }
                return options;
            },
            spread() {
                let spread = this.$store.getters[this.namespace + "/ticker"];
                //console.log("spread=", spread);
                return spread;
            },
            sortParameterOptions() {
                let options = [
                    {value: 'appd', text: 'appd'},
                    {value: 'rrr', text: 'avg mpe/avg mae '},
                    {value: 'avgMin', text: 'avg mae'},
                    {value: 'worstMin', text: 'worst mae'},
                    {value: 'avgMax', text: 'avg mpe'},
                    {value: 'bestMax', text: 'best mpe'}
                ];
                /*  if (this.$store.state.user.role === "superadmin") {
                 options.push({value: 'open', text: 'open'});
                 }*/
                return options;
            },
            tabTitle: {
                get() {
                    return this.$store.state[this.namespace].tabTitle;
                },
                set(tabTitle) {
                    this.$store.commit(this.namespace + '/setTabTitle', tabTitle);
                }
            },
            rowsPerPage: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.rowsPerPage;
                },
                set(rowsPerPage) {
                    this.$store.commit(this.namespace + '/chartParameters/setRowsPerPage', rowsPerPage);
                    this.makeQuery();
                }
            },
            page: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.page;
                },
                set(page) {
                    console.log("page=", page);
                    // this.$store.commit(this.namespace + '/chartParameters/setPage', page);
                    // this.makeQuery();
                }
            },
            commodityOptions() {
                return this.$store.getters["siteData/commoditiesArray"].map(x => {
                    return {value: x.symbol, text: x.name};
                });
            },
            commodity: {
                get() {
                    let commodity = this.$store.getters[this.namespace + '/c'][0];
                    return  commodity;
                },
                set(commodity) {
                    console.log("commodity.set() starting. this.numberOfContractsApart=", this.numberOfContractsApart);
                    this.$store.dispatch(this.namespace + '/changeCommodityLeg', {commodity: commodity, index: 0});
                    this.makeQuery();
                }
            },
            group() {
                return this.$store.getters[this.namespace + "/group"];
            },
            legs: {
                get() {
                    console.log("legs.get() starting.");
                    let temp = this.$store.state[this.namespace].search.legs;
                    if (!Array.isArray(temp)) {
                        temp = [1, 2, 3];
                        this.$store.commit(this.namespace + '/search/set', {key: "legs", value: temp});
                    }
                    return temp;
                },
                set(legs) {
                    this.$store.commit(this.namespace + '/search/set', {key: "legs", value: legs});
                    this.makeQuery();
                }
            },
            sortParameter: {
                get() {
                    return this.$store.state[this.namespace].search.sortParameter;
                },
                set(sortParameter) {
                    this.$store.commit(this.namespace + '/search/set', {key: "sortParameter", value: sortParameter});
                    this.makeQuery();
                }
            },
            position: {
                get() {
                    return this.$store.state[this.namespace].search.position;
                },
                set(position) {
                    this.$store.commit(this.namespace + '/search/set', {key: "position", value: position});
                    this.makeQuery();
                }
            },
            direction: {
                get() {
                    return this.$store.state[this.namespace].search.direction;
                },
                set(direction) {
                    this.$store.commit(this.namespace + '/search/set', {key: "direction", value: direction});
                    this.makeQuery();
                }
            },
            source: {
                get() {
                    return this.$store.state[this.namespace].search.source;
                },
                set(source) {
                    this.$store.commit(this.namespace + '/search/set', {key: "source", value: source});
                    this.makeQuery();
                }
            },
            minDays: {
                get() {
                    return this.$store.state[this.namespace].search.minDays;
                },
                set(minDays) {
                    this.$store.commit(this.namespace + '/search/set', {key: "minDays", value: minDays});
                    this.makeQuery();
                }
            },
            maxDays: {
                get() {
                    return this.$store.state[this.namespace].search.maxDays;
                },
                set(maxDays) {
                    this.$store.commit(this.namespace + '/search/set', {key: "maxDays", value: maxDays});
                    this.makeQuery();
                }
            },
            userConditions: {
                get() {
                    return this.$store.state[this.namespace].search.userConditions;
                }
            },
            openStart: {
                get() {
                    let openStart = this.$store.state[this.namespace].search.openStart;
                    console.log("get() openStart=", openStart);
                    return openStart;
                },
                set(openStart) {
                    this.$store.commit(this.namespace + '/search/setOpenStart', openStart);
                    // this.makeQuery();
                }
            },
            openEnd: {
                get() {
                    return this.$store.state[this.namespace].search.openEnd;
                },
                set(openEnd) {
                    this.$store.commit(this.namespace + '/search/setOpenEnd', openEnd);
                    // this.makeQuery();
                }
            },
            closeStart: {
                get() {
                    let closeStart = this.$store.state[this.namespace].search.closeStart;
                    console.log("get() closeStart=", closeStart);
                    return closeStart;
                },
                set(closeStart) {
                    this.$store.commit(this.namespace + '/search/setCloseStart', closeStart);
                    // this.makeQuery();
                }
            },
            closeEnd: {
                get() {
                    return this.$store.state[this.namespace].search.closeEnd;
                },
                set(closeEnd) {
                    this.$store.commit(this.namespace + '/search/setCloseEnd', closeEnd);
                    // this.makeQuery();
                }
            },
            tradePeriodExcludedSelector: {
                get() {
                    return this.$store.state[this.namespace].search.tradePeriodExcludedSelector;
                },
                set(tradePeriodExcludedSelector) {
                    this.$store.commit(this.namespace + '/search/setTradePeriodExcludedSelector', tradePeriodExcludedSelector);
                    // this.makeQuery();
                }
            },
        },
        watch: {
            openStart(newOpenStart, oldOpenStart) {
                console.log("watch: newOpenStart=", newOpenStart, " oldOpenStart=", oldOpenStart);
                this.makeQuery();
            },
            openEnd(openEnd) {
                console.log("watch: openEnd=", openEnd);
                this.makeQuery();
            },
            closeStart(newCloseStart, oldCloseStart) {
                console.log("watch: newCloseStart=", newCloseStart, " oldCloseStart=", oldCloseStart);
                this.makeQuery();
            },
            closeEnd(closeEnd) {
                console.log("watch: closeEnd=", closeEnd);
                this.makeQuery();
            },
            userConditions(userConditions) {
                console.log("watch: userConditions=", userConditions);
                this.makeQuery();
            },
            page: function (page) {
                console.log("watch: page=", page);
                this.makeQuery();
            },
            rowsPerPage: function (rowsPerPage) {
                console.log("watch: rowsPerPage=", rowsPerPage);
                this.makeQuery();
            },
            spread: function (spread) {
                console.log("watch: spread=", spread);
                this.makeQuery();
            },
            group: function (group) {
                console.log("watch: group=", group);
                this.makeQuery();
            },
            tradePeriodExcludedSelector: function (tradePeriodExcludedSelector) {
                console.log("watch: tradePeriodExcludedSelector=", tradePeriodExcludedSelector);
                this.makeQuery();
            },
            numberOfContractsApartOptions(numberOfContractsApartOptions) {
                console.log("watch: numberOfContractsApartOptions=", numberOfContractsApartOptions);
                let numberOfMonths = numberOfContractsApartOptions[numberOfContractsApartOptions.length-1].value;
                console.log("numberOfMonths=", numberOfMonths);
                console.log("this.numberOfContractsApart=", this.numberOfContractsApart);
                this.numberOfContractsApart = Math.min(this.numberOfContractsApart, numberOfMonths);

                this.makeQuery();
            }
        },
        methods: {
            startSearchTour() {
                console.log("startSearchTour() starting.");
                this.$tours['searchTour'].start();
            },
            addCondition: function () {
                console.log("addCondition() starting.");
                let arr = JSON.parse(JSON.stringify(this.$store.state[this.namespace].search.userConditions));
                let newObj = {
                    parameter: "appd",
                    comparator: ">",
                    value: 0
                };
                arr.push(newObj);
                this.$store.commit(this.namespace + '/search/setUserConditions', arr);
                // updateState();
            },
            makeQuery(resetPage = true) {
                console.log("makeQuery() starting.");
                //let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // console.log("generalForm=", generalForm);

                //  console.log("minDays=", this.minDays);
                //  console.log("typeof minDays=", typeof this.minDays);
                if (this.minDays === '' || this.maxDays === '') {
                    return;
                }
                //updateState(generalForm);

                this.trades = []
                if (resetPage) {
                    this.page = 1;
                }

                let query;
                let sourceCondition = "";
                let positionCondition = "";
                let legsCondition = "";

                //  console.log("this.$store.state[this.namespace]=", this.$store.state[this.namespace]);
                //  console.log("this.$store.state[this.namespace].search=", this.$store.state[this.namespace].search);
                //  console.log("this.$store.state[this.namespace].search.openStart=", this.$store.state[this.namespace].search.openStart);
                //  console.log("this.openStart=", this.openStart, " this.openEnd=", this.openEnd);
                let startingRangeConditions = this.tradePeriodExcludedSelector === 'startingRange' ? "" 
                                                : " open BETWEEN '" + this.openStart + "' AND '" + this.openEnd + "'";
                console.log("startingRangeConditions=", startingRangeConditions);

                console.log("this.legs=", this.legs);
                let numberOfContractsApartConditions = this.legs.some(x => x > 1) ? " numberOfContractsApart <= " + this.numberOfContractsApart +
                        " AND " : "";
                console.log("numberOfContractsApartConditions=", numberOfContractsApartConditions);

                let endingRangeConditions = this.tradePeriodExcludedSelector === 'endingRange' ? "" : " AND close BETWEEN '" + this.closeStart + "' AND '" + this.closeEnd + "'";
                console.log("endingRangeConditions=", endingRangeConditions);

                let tradeLengthConditions = this.tradePeriodExcludedSelector === 'tradeLength' ? "" : (this.tradePeriodExcludedSelector === 'startingRange' ? "" : " AND ") + " DATEDIFF(close, open ) >= " + this.minDays +
                        " and DATEDIFF(close, open ) <= " + this.maxDays;
                console.log("tradeLengthConditions=", tradeLengthConditions);

                let addOn = this.tradePeriodExcludedSelector === 'startingRange' ? " AND " : "";
                console.log("addOn=", addOn);

                let userCondition = "";
                console.log("this.userConditions=", this.userConditions);
                console.log("this.userConditions.length=", this.userConditions.length);
                if (this.userConditions.length > 0 && typeof this.userConditions[0] !== 'undefined') {
                    for (let i = 0; i < this.userConditions.length; i++) {
                        userCondition = userCondition + " and " + this.userConditions[i].parameter + " " + this.userConditions[i].comparator + " " + this.userConditions[i].value;
                    }
                }
                // console.log("userCondition=", userCondition);

                if (this.source == "spread") {
                    sourceCondition = " spread like '" + this.spread + "'";

                    query = `select * FROM data.trades3 where` + sourceCondition + ` and` + numberOfContractsApartConditions + startingRangeConditions + tradeLengthConditions + endingRangeConditions + userCondition + ` ORDER BY ` + this.sortParameter + ` ` + this.direction + `;`;
                    //  ` LIMIT ` + (this.page - 1) * this.rowsPerPage + ` , ` + this.rowsPerPage + `;`;
                 console.log("query=", query);

                } else {
                    if (this.source == "singleCommodity") {
                        sourceCondition = " and ticker like '" + this.commodity + "-%'";
                    } else if (this.source == "group") {
                        // console.log("this.group=", this.group);
                        let commodities = groupsObject[this.group].commodities;
                        // console.log("commodities=", commodities);

                        sourceCondition = commodities.reduce((result, commodity) => {
                            // console.log("result=", result);
                            // console.log("commodity=", commodity);
                            return result + " or ticker like '" + commodity + "-%'";
                        }, "");
                        sourceCondition = " and ( " + sourceCondition.replace("or", "") + " ) ";
                        // console.log("sourceCondition=", sourceCondition);
                    }

                    if (this.position !== "either") {
                        positionCondition = " and position like '%" + this.position + "%'";
                    }

                    legsCondition = " legs IN (" + this.legs.join(',') + ") and ";

                    let aggregator = "max";
                    if (this.direction === "asc") {
                        aggregator = "min";
                    }

                    console.log("this.page=", this.page, " this.rowsPerPage=", this.rowsPerPage);

                    query = `select SQL_CALC_FOUND_ROWS trade.*
                FROM data.trades3 AS trade
                INNER JOIN
                   (
                                SELECT ticker, ` + aggregator + `(` + this.sortParameter + `) AS ` + this.sortParameter + `
                                FROM data.trades3
                                where ` + legsCondition + numberOfContractsApartConditions + startingRangeConditions + tradeLengthConditions + endingRangeConditions +
                             sourceCondition + positionCondition + userCondition + ` GROUP BY ticker
                    ) AS trade2
                ON trade.ticker = trade2.ticker AND trade.` + this.sortParameter + ` = trade2.` + this.sortParameter +
                            ` HAVING ` + legsCondition + numberOfContractsApartConditions + startingRangeConditions + tradeLengthConditions + endingRangeConditions +
                             sourceCondition + positionCondition + userCondition +
                            ` ORDER BY ` + this.sortParameter + ` ` + this.direction +
                            ` LIMIT ` + (this.page - 1) * this.rowsPerPage + ` , ` + this.rowsPerPage + `;`;

                }
               // console.log("query=", query);
                this.$store.commit(this.namespace + '/search/setQuery', query);
            }
        }
    };

</script>
